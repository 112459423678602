<template>
    <slot name="rowcount">
        <div class="px-2 d-flex text-nowrap">
            <template v-if="gridControl.arrayData">
                <b v-if="gridControl.filter?.filterObject?.appliedFilterString" class="align-self-center">
                    <!--
                    <span v-if="gridControl.filter?.rowCount != null" :title="$t('Current Row')">{{number.format(dataObject.current?.index+1, '1 234')}} / </span>
                    -->
                    <span :title="$t('Filtered Rows')">{{number.format(gridControl.arrayData.filteredRowCount, '1 234')}}</span> <span :title="$t('Total Rows')">({{number.format(gridControl.arrayData.rowCount, '1 234')}})</span>
                </b>
                <b v-else class="align-self-center">
                    <!--
                    <span v-if="gridControl.filter?.rowCount != null" :title="$t('Current Row')">{{number.format(dataObject.current?.index+1, '1 234')}} / </span>
                    -->
                    <span :title="$t('Total Rows')">{{number.format(gridControl.arrayData.rowCount, '1 234')}}</span>
                </b>
            </template>
        </div>
    </slot>
    <slot name="newrecord"></slot>
    <slot name="filter">
        <div v-if="gridControl.filter"
            class="filterstring d-flex align-items-baseline text-truncate mt-1 d-flex align-items-baseline">
            <label class="text-truncate" :title="gridControl.filter.filterObject.prettyString">
                {{gridControl.filter.filterObject.prettyString}}
            </label>
            
            <button v-if="gridControl.filter.filterObject.filterString && gridControl.filter.filterObject.hasChanges" 
                @click="gridControl.filter.filterObject.apply()" 
                class="btn btn-outline-primary btn-sm px-1 pt-0 ms-2">
                {{$t('Apply')}}
            </button>
            <button v-if="gridControl.filter.filterObject.filterString"
                class="btn btn-sm btn-link px-1 pt-0 ms-2"
                @click="gridControl.filter.filterObject.clear()">
                {{$t('Clear')}}
            </button>
        </div>
    </slot>
    <slot name="misc"></slot>
</template>

<script setup lang="ts">
import number from 'o365.modules.utils.number.js';
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';

const gridControl = useBaseGridControl();
</script>